@font-face {
  font-family: 'EuclidCircularA';
  src: url('./Assets/EuclidCircularA-Medium-WebXL.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
	box-sizing: border-box;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

body {
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	font-family: 'EuclidCircularA', sans-serif;
}


:root {
	--d: 3000ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(0, 164, 95, 1);
	--c2: rgba(0, 164, 95, 0.2);
}

.wallboard {


	border: 0.35rem solid;
	border-radius: 20px;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}

.wallboard:nth-child(2) {
	border-image: radial-gradient(ellipse at var(--gradX) var(--gradY), var(--c1), var(--c1) 10%, var(--c2) 40%) 30;
	animation: borderRadial var(--d) linear infinite forwards;
}

@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}